<template>
  <div>
    <main class="sub __contact __guide">
      <div class="inner __faq">
        <div class="tit req-type">
          <h1>호구153교육 이수내역</h1>
          <p class="stit">이수한 교육 내역을 확인 가능합니다.</p>
        </div>
        <div class="sub-cont">
          <div class="guide-tapmenu-wrap">
            <ul style="cursor: pointer">
              <li>
                <router-link
                  :class="{ on: routeName === 'eduApply' }"
                  to="/eduApply"
                >
                  교육신청
                </router-link>
              </li>

              <li>
                <router-link
                  :class="{ on: routeName === 'videoWatch' }"
                  to="/video-watch"
                >
                  교육 동영상
                </router-link>
              </li>
              <li>
                <router-link
                  :class="{ on: routeName === 'resumeEdu' }"
                  to="/resume/edu"
                >
                  교육 이수 내역
                </router-link>
              </li>
            </ul>
          </div>
          <ul class="education-list" id="educationList">
            <li v-for="(education, ix) in educations" :key="`faq_${ix}`">
              <div
                class="q"
                :class="{ on: activeNum === ix }"
                @click="openAnswer(ix)"
              >
                {{ education.className }} -
                {{ education.subjectName }}
              </div>
              <div class="a" :class="{ on: activeNum === ix }">
                <div class="input-form-wrap emptyList">
                  <div class="input-form-wrap">
                    <div class="input-form">
                      <div class="t-head">교육과정</div>
                      <div class="t-doc">
                        <input
                          v-model="education.className"
                          name=""
                          id=""
                          type="text"
                          class="text"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="input-form">
                      <div class="t-head">이수번호</div>
                      <div class="t-doc">
                        <input
                          v-model="education.licenseNo"
                          type="text"
                          class="text"
                          value="HOGOO153ABC"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="input-form">
                      <div class="t-head">교육장소</div>
                      <div class="t-doc">
                        <input
                          v-model="education.classroom"
                          type="text"
                          class="text"
                          value="본사"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="input-form">
                      <div class="t-head">과목명</div>
                      <div class="t-doc">
                        <input
                          v-model="education.subjectName"
                          name=""
                          id=""
                          type="text"
                          class="text"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="input-form">
                      <div class="t-head">교육수강일</div>
                      <div class="t-doc">
                        <input
                          v-model="education.strtDt"
                          :id="`entryDate_${ix}`"
                          type="text"
                          class="text"
                          value=""
                          disabled
                        />
                      </div>
                    </div>
                    <div class="input-form">
                      <div class="t-head">교육이수일</div>
                      <div class="t-doc">
                        <input
                          v-model="education.endDt"
                          :id="`gradDate_${ix}`"
                          type="text"
                          class="text"
                          value=""
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div class="empty_form" v-if="educations.length === 0">
            교육사항이 존재하지 않습니다.
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getHogoo153EduComplList } from 'Api/modules'
export default {
  data() {
    return {
      educations: [],
      activeNum: null,
    }
  },
  async created() {
    const res = await getHogoo153EduComplList()
    this.educations = res
  },
  methods: {
    openAnswer(ix) {
      if (this.activeNum === ix) this.activeNum = null
      else {
        this.activeNum = ix
      }
    },
  },
}
</script>

<style>
.empty_form {
  padding-top: 140px;
  height: 180px;
  border: 1px;
  border-style: none none solid none;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
</style>
